import {apiToken, baseUrl} from "./index";

const url = baseUrl + "/frontend/get-ferialjob-options";
const submitUrl = baseUrl + "/frontend/jobsuche";


export const fetchData = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken
        },
    });
    return await response.json();
};

export const submitJobSearch = async (data: { [key: string]: any }) => {
    const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
        body: JSON.stringify(data)
    });

    return await response.json();
};

export const getJob = async (id: string | number) => {
    const jobUrl = submitUrl + '/' + id;
    try {
        const response = await fetch(jobUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': 'Bearer ' + apiToken

            },
        });
        return await response.json();
    } catch (e) {
        return null
    }
};