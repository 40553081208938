import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Input from "../styles/input.module.scss";
import {useFormContext} from "react-hook-form";

interface ILocationState {
    address: string;
}

interface ILocationProps {
    className: string;
    setData: any;
    stateField: string;
    name: string;
    value: string
}

export class LocationSearchInput extends React.Component<ILocationProps, ILocationState> {

    constructor(props) {
        super(props);
        this.state = {address: ''};
    }


    handleChange = address => {
        this.setState({address});
        if (this.props.stateField.toLocaleLowerCase() == "adresse") {
            this.props.setData({id: this.props.stateField, adresse: address});
            this.setState({address: address})
        } else if (this.props.stateField.toLocaleLowerCase() == "bs_adresse") {
            this.props.setData({id: this.props.stateField, bs_adresse: address});
        }
    };

    handleSelect = address => {
        this.setState({address: address});
        geocodeByAddress(address)
            .then(results => this.extractFromAdress(results[0]))
            .then(addressJson => {
                    if (this.props.stateField.toLocaleLowerCase() == "adresse") {
                        this.props.setData({id: this.props.stateField, adresse: JSON.stringify(addressJson)});
                    } else if (this.props.stateField.toLocaleLowerCase() == "bs_adresse") {
                        this.props.setData({id: this.props.stateField, bs_adresse: JSON.stringify(addressJson)});
                    }
                }
            )
    };

    async extractFromAdress(address) {
        let addressJson: any = {};

        for (let i = 0; i < address.address_components.length; i++) {
            const fieldName = address.address_components[i].types[0];
            addressJson[fieldName] = address.address_components[i].long_name;
        }

        addressJson.value = address.formatted_address;
        addressJson.latlng = await getLatLng(address);
        return addressJson;
    }


render() {

        return (

            <div>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    name={this.props.name}
                >
                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                        <div>
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                Adresse* (Bitte wählen Sie einen Eintrag aus)
                                </span>
                            <input required={true} className={Input.textInput}
                                   {...getInputProps({
                                       placeholder: 'Search Places ...',
                                       className: `${Input.textInput}`,
                                   })}
                            />
                            <div className={Input.locationDropdownContainer}>
                                {/*{loading && <div>Loading...</div>}*/}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    const style = suggestion.active
                                        ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                        : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                    return (
                                        <div key={suggestion.description}
                                             {...getSuggestionItemProps(suggestion, {
                                                 className,
                                                 style,
                                             })}
                                        >
                                            <div className={Input.locationSuggestion}>
                                                {suggestion.description}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        );
    }
}