import React, {FunctionComponent} from "react";
import TextInput from "../../components/TextInput";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import {handleChange} from "../../helpers";


import HelperStyles from "../../styles/helper.module.scss";
import {SearchTutoringStep} from "./SearchTutoringData";

const FirstStep: FunctionComponent<SearchTutoringStep> = ({data, setData, formValues}) => {

    const bezirkMultiOptions = formValues.bezirk.map((b) => ({
        label: b.bezeichnung,
        value: b.id
    }));


    const handleSelect = (selected: string | number) => {
        setData({bevorzugter_bezirk: selected})
    };

    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Kontakt Infos & Adresse</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.vorname}
                        type="text"
                        label="Vorname"
                        name="vorname"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.nachname}
                        type="text"
                        label="Nachname"
                        name="nachname"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.telefon}
                        type="tel"
                        label="Telefon"
                        name="telefon"
                        required={true}/>
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.email}
                        type="text"
                        label="Email"
                        name="email"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.strasse}
                        type="text"
                        label="Straße, Nr."
                        name="strasse"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.plz}
                        type="number"
                        label="PLZ"
                        name="plz"
                        required={true}/>
                </div>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.ort}
                        type="text"
                        label="Ort"
                        name="ort"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiSelectDropdown name={'bevorzugter_bezirk'} options={bezirkMultiOptions}
                                         selectedValues={data.bevorzugter_bezirk}
                                         onSelect={handleSelect} label={'Bevorzugte Bezirke'}/>
                </div>
            </div>
        </>
    );
};

export default FirstStep;
