import React, {FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";

import Input from "../styles/input.module.scss";

const TextArea: FunctionComponent<{
  value: string;
  name: string;
  label: string;
  required: boolean;
  onInputChange: (data: {value: any, id: string})=> any;
}> = ({ value, name, label, required, onInputChange }) => {
  const { register, errors } = useFormContext();

  return (
    <label className={Input.textLabel} htmlFor={name}>
      {label}
      {required && "*"}
      <textarea
        onChange={({target: {value}}) => onInputChange({id: name, value})}
        value={value}
        id={name}
        name={name}
        aria-invalid={errors.name ? "true" : "false"}
        className={Input.textInput}
        rows={4}
        ref={register({
          required: required ? "Bitte fülle dieses Feld aus" : false,
          maxLength: {
            value: 1024,
            message: "Du hast die maximale Länge überschritten"
          }
        })}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<span className={Input.errorMessage}></span>}
      />
    </label>
  );
};

export default TextArea;
