import {useState} from "react";

type UseSubmitConfig = {
    submitAction: (d: any) => Promise<any>;
    data: () => any;
    showErrorsAction: (e: any) => void
    errorText: string;
    formFinished: (a?: any) => void
}
export const useSubmit = (conf: UseSubmitConfig) => {
    const formHasErrors = (data: any) => {
        const errors = data.form_errors;
        Object.keys(errors).forEach(error => {
            conf.showErrorsAction(errors[error])
        })
    };
    const [sending, setSending] = useState(false)
    const onSubmit = () => {
        setSending(true);
        conf.submitAction(conf.data()).then(data => {
            setSending(false);
            data.status === 'success' ? conf.formFinished() : formHasErrors(data)
        }).catch(() => {
            setSending(false)
            conf.showErrorsAction(conf.errorText)})
    }

    return {sending, onSubmit}
}