import React, {useEffect, useState} from "react";

import {getJob} from '../../api/SearchJobForm'
import moment from 'moment';
import 'moment/locale/de-at';


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import InputStyle from "../../styles/input.module.scss";
import JDStyles from '../../styles/jobdetails.module.scss'
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons/faMailBulk";

const JobDetail = ({id}) => {


    const monthName = {
        m1: 'Jänner',
        m2: 'Februar',
        m3: 'März',
        m4: 'April',
        m5: 'Mai',
        m6: 'Juni',
        m7: 'Juli',
        m8: 'August',
        m9: 'September',
        m10: 'Oktober',
        m11: 'November',
        m12: 'Dezember'
    };
    const months = Object.keys(monthName);


    const beschaftigungenName = {
        praktikumhtl: 'HTL Praktikum',
        praktikumkig: '',
        praktikumtou: '',
        ferialjob: 'Ferialjob',
        pflichtpraktikum: 'Pflichtpraktikum',
        nebenjob: 'Nebenjob',
        samstagsaushilfe: 'Samstagsaushilfe'
    };
    const beschaftigungen = Object.keys(beschaftigungenName);

    const bewerbungenName = {
        b_schriftlich: 'Schriftlich',
        b_telefonisch: 'Telefonisch',
        b_email: 'E-Mail',
        b_online: 'Online',
    };
    const bewerbung = Object.keys(bewerbungenName);


    const [jobDetails, setJobDetails] = useState<any>({});
    const [jobFound, setJobFound] = useState(true);

    useEffect(() => {
        try {
            getJob(id).then(response => {
                if (response.status === 'error') {
                    setJobFound(false);
                } else {
                    setJobFound(true);
                }
                setJobDetails(response)
            })
        } catch (error) {
            console.log(error)
        }
    }, []);

    function printJobDetails() {
        window.print();
    }

    moment.locale('de-at');
    const date = moment(jobDetails.created_at).format('D. MMMM YYYY');


    return (
        <>
            {jobFound ? (
                <>
                    <div className={JDStyles.printContent}>
                        <div className={JDStyles.addressWrapper}>

                            <h3>{jobDetails.firma}</h3>
                            <p>{jobDetails.strasse}</p>
                            <p><i>{jobDetails.plz}</i> {jobDetails.ort}</p>
                            <p><i>{jobDetails.anrede}</i> {jobDetails.vorname} {jobDetails.nachname}</p>
                            <div className={JDStyles.contactWrapper}>
                                {jobDetails.telefon ?
                                    <p><span className={JDStyles.icon}><strong><FontAwesomeIcon
                                        icon={faPhone}/></strong></span> <a
                                        href={`tel:${jobDetails.telefon}`}>{jobDetails.telefon}</a></p> : ''}
                                {jobDetails.telefon ?
                                    <p><span className={JDStyles.icon}><strong>Fax:</strong></span> {jobDetails.telefax}
                                    </p> : ''}
                                {jobDetails.email ?
                                    <p><span className={JDStyles.icon}><strong><FontAwesomeIcon
                                        icon={faMailBulk}/></strong></span>
                                        <a href={`mailto:${jobDetails.email}`}>{jobDetails.email}</a></p> : ''}
                            </div>
                            <div className={JDStyles.homepage}>
                                <i><a href={jobDetails.homepage}>{jobDetails.homepage}</a></i>
                            </div>
                        </div>
                        <div className={JDStyles.printWrapper}>
                            <button onClick={() => printJobDetails()}
                                    className={JDStyles.printBtn + " " + InputStyle.btn}>
                                Drucken
                            </button>
                        </div>
                        <div className={JDStyles.printLogoWrapper}>
                            <img className={JDStyles.printLogo} src={require('../../Static/jugendservice_logo.png')}  alt={"Logo"} style={{display:"none"}}/>
                        </div>
                        <div className={JDStyles.descriptionWrapper}>
                            <p className={JDStyles.date}>Anzeige vom {date}</p>
                            <div className={JDStyles.description}>
                                <div className={JDStyles.titleWrapper}>
                                    <h2 className={JDStyles.titleCenter}>{jobDetails.job_title}</h2>
                                </div>
                                <h3>Jobbeschreibung</h3>
                                <p dangerouslySetInnerHTML={{__html: jobDetails.taetigkeit && jobDetails.taetigkeit.replace(/\n/g, '<br/>')}}></p>
                                <h3>Vorkenntnisse</h3>
                                <p dangerouslySetInnerHTML={{__html: jobDetails.vorkenntnisse && jobDetails.vorkenntnisse.replace(/\n/g, '<br/>')}}></p>
                                <h3>Bevorzugte Ausbildung</h3>
                                <p dangerouslySetInnerHTML={{__html: jobDetails.bevausbildung && jobDetails.bevausbildung.replace(/\n/g, '<br/>')}}></p>
                            </div>
                            <div className={JDStyles.details}>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Zeitraum</div>
                                    <div>{months.map(month => {
                                        return jobDetails[month] ? `${monthName[month]}, ` : ''
                                    })}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Dauer</div>
                                    <div>{jobDetails.dauer}
                                    </div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Gehalt</div>
                                    <div>{jobDetails.gehalt}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Mindestalter</div>
                                    <div>{jobDetails.mindestalter}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Anz. der Jobs</div>
                                    <div>{jobDetails.gesamtanz}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Art der <br/>Beschäftigung</div>
                                    <div>{beschaftigungen.map(beschaftigung => {
                                        return (
                                            jobDetails[beschaftigung] ? `${beschaftigungenName[beschaftigung]}, ` : ''
                                        )
                                    })}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Wohnmöglichkeit</div>
                                    <div>{jobDetails.wohnmoeglichkeit ? 'Ja' : 'Nein'}</div>
                                </div>
                                <div className={JDStyles.flex}>
                                    <div className={JDStyles.detailHead}>Bewerbung</div>
                                    <div>{bewerbung.map(bewerbung => {
                                        return (
                                            jobDetails[bewerbung] ? `${bewerbungenName[bewerbung]}, ` : ''
                                        )
                                    })}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : 'Wir konntent leider keinen Job mit dieser id Finden'}
        </>
    )
};

export default JobDetail;