import React, {CSSProperties, FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";
import Input from "../styles/input.module.scss";
import HelperStyles from "../styles/helper.module.scss";
import InfoSign from "./InfoSign";


export type LabelValueObjDropdown = {
    label: string;
    value: number|string;
    freeText?: boolean;
};

const CustomDropdown: FunctionComponent<{
    options: undefined | LabelValueObjDropdown[];
    name: string;
    value: string;
    label: string;
    required: boolean;
    freeTextId?: string;
    textValue?: string;
    infoBox?: { text: string, style: CSSProperties };
    onChange?: (data: { value: any, id: string }) => void;

}> = ({options, name, value, label, required, infoBox, onChange, freeTextId, textValue}) => {
    const {errors, register} = useFormContext();

    return (
        <>
            <label htmlFor={name}>
                <div className={HelperStyles.flex}>
                    <span style={{display: 'flex'}}>{label}{required && '*'} {infoBox &&
                    <InfoSign text={infoBox.text} style={infoBox.style}/>}</span>
                </div>
                <select
                    className={Input.dropDown}
                    ref={register({
                        required: required ? "Bitte wähle eine Option" : false
                    })}
                    name={name}
                    id={name}
                    onChange={(e) => {
                        onChange ? onChange({id: name, value: e.target.value}): void(0);
                    }}
                >
                    <option value="" disabled selected>Bitte wähle eine Option</option>
                    {options
                        ? options.map(option => (
                            <option key={option.value}
                                    selected={value === `${option.value}` ? true : false}
                                    value={option.value}
                            >
                                {option.label}
                            </option>
                        ))
                        : ""}
                </select>
            </label>
            {
                options ?
                    options.some(o => o.value+'' === value && o.freeText) &&
                    <label className="input_textLabel__3SBvZ" htmlFor="beruf"><span
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>{options.filter(option => option.value+'' === value && option.freeText)[0].label}</span>
                        <input id="beruf" name={freeTextId} type="text"
                               onChange={(e) => {
                                   freeTextId && onChange ? onChange({id: freeTextId, value: e.target.value}) : void (0)
                               }}
                               className={`${Input.textInput} ${errors.name ? Input.error : ""}`}
                               value={textValue}/></label> : ''
            }
            <ErrorMessage
                errors={errors}
                name={name}
                as={<span className={Input.errorMessage}></span>}
            />
        </>
    );
};

export default CustomDropdown;
