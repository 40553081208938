import React, {FunctionComponent} from "react";
import CustomDropdown, {LabelValueObjDropdown} from "../../components/CustomDropdown";
import TextInput from "../../components/TextInput";
import {LocationSearchInput} from "../../components/LocationSearchInput";

import HelperStyles from "../../styles/helper.module.scss";
import InputStyles from "../../styles/input.module.scss";
import {handleChange} from "../../helpers";
import {EnterJobStep} from "./EnterJobData";

const FirstStep: FunctionComponent<EnterJobStep> = ({data, setData, formValues}) => {
    const sexOptions: LabelValueObjDropdown[] = [
        {label: 'Herr', value: 'herr'},
        {label: 'Frau', value: 'frau'},
        {label: 'Andere', value: 'andere'}]

    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Firmenadresse</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.firma}
                        type="text"
                        label="Firma"
                        name="firma"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <LocationSearchInput
                        setData={setData}
                        stateField={"adresse"}
                        name="adresse"
                        value="value"
                        className={InputStyles.btn}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <CustomDropdown
                        label="Bezirk"
                        value={data.bezirk_id}
                        name="bezirk_id"
                        options={formValues.bezirk.map(b => ({label: b.bezeichnung, value: b.id}))}
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.telefon}
                        type="tel"
                        label="Telefon"
                        name="telefon"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.telefax}
                        type="tel"
                        label="Telefax"
                        name="telefax"
                        required={false}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.email}
                        type="email"
                        label="E-Mail"
                        name="email"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.homepage}
                        type="url"
                        label="Webseite (z.B. https://mywebsite.at/)"
                        name="homepage"
                        required={false}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Kontaktperson</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <CustomDropdown
                        onChange={handleChange(setData)}
                        value={data.anrede}
                        options={sexOptions}
                        label="Anrede"
                        name="anrede"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.fvorname}
                        type="text"
                        label="Vorname"
                        name="fvorname"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.fnachname}
                        type="text"
                        label="Nachname"
                        name="fnachname"
                        required={true}
                    />
                </div>
            </div>

        </>
    );
};

export default FirstStep;
