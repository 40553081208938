export const Steps = {
    1: [
        "firma",
        "bezirk_id",
        "telefon",
        "telefax",
        "email",
        "homepage",
        "anrede",
        "fvorname",
        "fnachname",
    ],
    2: [
        "bs_firma",
        "bs_bezirk_id",
        "bs_telefon",
        "bs_telefax",
        "bs_email",
        "bs_homepage",
        "bs_anrede",
        "bs_vorname",
        "bs_nachname",
    ],

    3: [
        "vorkenntnisse",
        "dauer",
        "gehalt",
        "gueltig_bis",
        "mindestalter",
        "gesamtanz",
        "sozial",
        'm1',
        'pflichtpraktikum',
        'job_title'
    ],

    4: [
        "datenschutz"
    ],
    5: []
};

export const StepsHeading = {
    1: 'Kontakt',
    2: 'Betriebsstätte',
    3: 'Jobbeschreibung',
    4: 'Sonstiges',
    5: 'Abschluss'
}