import React, {CSSProperties, FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";

import Input from "../styles/input.module.scss";
import InfoSign from "./InfoSign";

const TextInput: FunctionComponent<{
    value: string;
    name: string;
    label: string;
    type: string | null;
    required: boolean;
    onInputChange: (data: { value: any, id: string }) => any;
    max?: string;
    min?: string;
    errorMessage?: string;
    infoBox?: { text: string, style: CSSProperties }
}> = ({value, name, label, type, required, onInputChange, max, min, errorMessage, infoBox}) => {
    const {register, errors} = useFormContext();

    return (
        <label className={Input.textLabel} htmlFor={name}>
            <span style={{display: 'flex', alignItems: 'center'}}>{label}
                {required ? "*" : ""}
                {infoBox && <InfoSign text={infoBox.text} style={infoBox.style}/>}</span>
            <input
                max={max}
                min={min}
                onChange={({target: {value}}) => onInputChange({id: name, value})}
                defaultValue={value}
                id={name}
                name={name}
                type={type ? type : "text"}
                aria-invalid={errors.name ? "true" : "false"}
                className={`${Input.textInput} ${errors.name ? Input.error : ""}`}
                ref={register({
                    required: required ? errorMessage ? errorMessage : "Bitte fülle dieses Feld aus" : false,
                    maxLength: {
                        value: 255,
                        message: "Du hast die maximale Länge überschritten"
                    }
                })}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                as={<span className={Input.errorMessage}></span>}
            />
        </label>
    );
};

export default TextInput;
