import React, {FunctionComponent} from "react";
import TextInput from "../../components/TextInput";
import CustomDropdown, {LabelValueObjDropdown} from "../../components/CustomDropdown";
import {useDispatch, useSelector} from "react-redux";
import {setBsOut} from "../../redux/actions/EnterJob.actions";
import {handleChange} from "../../helpers";

import HelperStyles from "../../styles/helper.module.scss";
import {EnterJobStep} from "./EnterJobData";
import {LocationSearchInput} from "../../components/LocationSearchInput";
import InputStyles from "../../styles/input.module.scss";


const SecondStep: FunctionComponent<EnterJobStep> = ({data, setData, formValues}) => {
    const dispatch = useDispatch();
    const toggleCheckbox = () => {
        dispatch(setBsOut());
    };

    const bsOut = useSelector((state: any) => state.EnterJobForm.bsOut)

    const sexOptions: LabelValueObjDropdown[] = [
        {label: 'Herr', value: 'herr'},
        {label: 'Frau', value: 'frau'},
        {label: 'Andere', value: 'andere'}]

    return (
        <>
            <div className={HelperStyles.flex}>
                <label className={`${HelperStyles.wfull} ${HelperStyles.flex}`}>
                    <input
                        type="checkbox"
                        onChange={toggleCheckbox}
                        defaultChecked={bsOut}
                    />
                    Betriebsstätte abweichend von Firmenadresse?
                </label>
            </div>
            {bsOut ? (
                <>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w25}>
                            <h2 className={HelperStyles.textLeft}>Betriebsstätte</h2>
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.wfull}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_firma}
                                type="text"
                                label="Firma"
                                name="bs_firma"
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.wfull}>
                            <LocationSearchInput
                                setData={setData}
                                stateField={"bs_adresse"}
                                name="bs_adresse"
                                className={InputStyles.btn}
                                value="value2"
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.wfull}>
                            <CustomDropdown
                                label="Bezirk"
                                value={data.bs_bezirk_id}
                                name="bs_bezirk_id"
                                options={formValues.bezirk.map(b => ({label: b.bezeichnung, value: b.id}))}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_telefon}
                                type="tel"
                                label="Telefon"
                                name="bs_telefon"
                                required={true}
                            />
                        </div>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_telefax}
                                type="tel"
                                label="Telefax"
                                name="bs_telefax"
                                required={false}
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_email}
                                type="email"
                                label="E-Mail"
                                name="bs_email"
                                required={true}
                            />
                        </div>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_homepage}
                                type="url"
                                label="Homepage (z.B. https://mywebsite.at/)"
                                name="bs_homepage"
                                required={false}
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w25}>
                            <h2 className={HelperStyles.textLeft}>Kontaktperson</h2>
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.wfull}>
                            <CustomDropdown
                                options={sexOptions}
                                onChange={handleChange(setData)}
                                value={data.bs_anrede}
                                label="Kontaktperson"
                                name="bs_anrede"
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_vorname}
                                type="text"
                                label="Vorname"
                                name="bs_vorname"
                                required={true}
                            />
                        </div>
                        <div className={HelperStyles.w50}>
                            <TextInput
                                onInputChange={handleChange(setData)}
                                value={data.bs_nachname}
                                type="text"
                                label="Nachname"
                                name="bs_nachname"
                                required={true}
                            />
                        </div>
                    </div>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default SecondStep;
