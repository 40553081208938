import React, {FunctionComponent} from "react";
import {useFormContext} from "react-hook-form";

import HelperStyles from "../styles/helper.module.scss";

const RadioButtons: FunctionComponent<{
    options: { label: string, value: any, selected: boolean | 0 | 1 }[];
    name: string;
    title: string;
    onChange?: (data: { value: any, id: string }) => void;
    valueParser?: Function
}> = ({options, name, title, onChange, valueParser}) => {
    const {register} = useFormContext();
    const dataOptions = options ? [...options] : null;

    return (
        <div className={`${HelperStyles.mb1}`}>
            <div className={`${HelperStyles.flex} ${HelperStyles.mb1}`}>
                <span>{title}</span>
            </div>
            {dataOptions !== null
                ? dataOptions.map(option => {
                    return (
                        <label key={title+option.value} className={`${HelperStyles.flex}`} htmlFor={option.value}>
                            <input
                                defaultChecked={!!option.selected}
                                ref={register}
                                type="radio"
                                name={name}
                                id={option.value}
                                checked={!!option.selected}
                                value={valueParser? valueParser(option.value): option.value}
                                onChange={() => {
                                    onChange ? onChange({
                                        value: valueParser ? valueParser(option.value) : option.value,
                                        id: name
                                    }) : void (0)
                                }}
                            />
                            {option.label}
                        </label>
                    );
                })
                : ""}
        </div>
    );
};

export default RadioButtons;
