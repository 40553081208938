import React, {useState} from "react";
import MultiSelectDropdown from "./MultiSelectDropdown";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import ReactTooltip from "react-tooltip";

import HelperStyles from "../styles/helper.module.scss";
import InputStyles from '../styles/input.module.scss'
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";

type UserAddNewTutoring = {
    faecher: {label: string, value: number | string}[];
    stufen: {label: string, value: number | string}[];
    handleChange: Function,
    name: string
    data: {[key: string]: any}
}

const UserAddNewTutoring: React.FC<UserAddNewTutoring> = ({faecher, stufen, handleChange, name, data}) => {


    const [userChoices, setUserChoices] = useState<any>(data || [{
        faecher_id: faecher[0].value,
        stufe_id: [],
        anmerkung: ''
    }]);

    const addUserChoice = (e) => {
        e.preventDefault();
        setUserChoices([...userChoices, {
            faecher_id: '',
            stufe_id: [],
            anmerkung: ''
        }])
    };

    const setUserFach = (id, e) => {
        let userChoiceArray = [...userChoices];
        userChoiceArray[id].faecher_id = e.target.value;
        setUserChoices(userChoiceArray)
    };

    const setUserStufe = (id, stufen_id) => {
        let userChoiceArray = [...userChoices];
        userChoiceArray[id].stufe_id = stufen_id;
        setUserChoices(userChoiceArray)
    };

    const setUserAnmerkung = (e, id) => {
        let userChoiceArray = [...userChoices];
        userChoiceArray[id].anmerkung = e.target.value;
        setUserChoices(userChoiceArray)
    };

    const deleteItem = (e, id) => {
        e.preventDefault()

        let choices = userChoices;
        if (userChoices.length > 1) {
            choices.splice(id, 1)
        }
        setUserChoices([...choices])
    };

    console.log(faecher)

    const sonstigesId = faecher.find(fach => fach.label === 'Sonstiges')?.value;

    console.log(sonstigesId)

    return (
        <>
            <div className={`${InputStyles.multiInputContainer}`} onChange={() => handleChange(userChoices, name)}>
                {userChoices.map((choice, id) => {
                    return (
                        <>
                            <div className={`${HelperStyles.flex}  ${HelperStyles.itemsCenter}`}>
                                <div className={HelperStyles.w33}>
                                    {Number(sonstigesId) === Number(userChoices[id].faecher_id) ? <>
                                    <div className={InputStyles.infoButton} data-tip={'Bitte Fächer bei Anmerkungen angeben'}><FontAwesomeIcon icon={faInfo}/></div>
                                        <ReactTooltip effect={'solid'}/>
                                    </>: ''}
                                    <select value={userChoices[id].faecher_id} className={InputStyles.dropDown} onChange={(e) => setUserFach(id, e)}>
                                        <option value={''}>Bitte auswählen</option>
                                        {faecher.map((fach) => {
                                            return (
                                                <option value={fach.value}>{fach.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className={HelperStyles.w33}>
                                    <MultiSelectDropdown required={true} name={'bevorzugter_bezirk'} label={''} options={stufen}
                                                         selectedValues={userChoices[id].stufe_id}
                                                         onSelect={(selected) => setUserStufe(id, selected)} key={id}/>
                                </div>
                                <div className={HelperStyles.w33}>
                                    <input className={InputStyles.textInput} value={userChoices[id].anmerkung}
                                           name={'UserFachChoiceMore' + id} type={'text'}
                                           required={Number(sonstigesId) === Number(userChoices[id].faecher_id)}
                                           placeholder={'Anmerkungen'}
                                           onChange={(e) => setUserAnmerkung(e, id)}/>
                                </div>
                                <button disabled={userChoices.length <= 1} onClick={(e) => deleteItem(e, id)}
                                        className={InputStyles.btnDelete}><FontAwesomeIcon
                                    style={{fontSize: '25px', color: '#fff', cursor: 'pointer'}} icon={faTrash}/>
                                </button>
                            </div>
                        </>
                    )
                })}
                {/*<CustomDropdown options={faecher} name="UserFachChoice" value={} label={} required={}/>*/}
                {/*<MultiSelectDropdown />*/}
                {/*<TextInput value={} name={} label={} type={} required={} onInputChange={}/>*/}
                <div className={`${HelperStyles.flex} ${HelperStyles.justifyEnd}`}>
                    <div className={`${HelperStyles.wfull} ${HelperStyles.flex} ${HelperStyles.justifyEnd}`}>
                        <button className={`${InputStyles.btn} ${InputStyles.btnYellow} ${HelperStyles.mr0}`}
                                onClick={addUserChoice}><FontAwesomeIcon style={{fontSize: '30px'}}
                                                                         icon={faPlusSquare}/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAddNewTutoring;