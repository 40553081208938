import React, {FunctionComponent, useEffect, useState} from "react";
import CustomDropdown from "../../components/CustomDropdown";
import TextInput from "../../components/TextInput";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import {handleChange} from "../../helpers";

import moment from "moment";

import HelperStyles from "../../styles/helper.module.scss";
import InfoSign from "../../components/InfoSign";
import {InfoButtonGreySmall, InfoButtonYellowBig} from "../../helpers/cssInline";
import RadioButtons from "../../components/RadioButtons";
import {GiveTutoringStep} from "./GiveTutoringData";

const FirstStep: FunctionComponent<GiveTutoringStep> = ({data, setData, formValues}) => {

    const options = formValues.bezirk.map(b => ({
        label: b.bezeichnung,
        value: b.id
    }))
    const bezirkMultiOptions = formValues.bezirk.map(b => ({
        label: b.bezeichnung,
        value: b.id
    }));

    const highestEducation = formValues.bildungsabschluss.map(b => ({
        label: b.name,
        value: b.id,
        freeText: !!b.freetext_allowed
    }))

    const [ferienNachhilfe, setFeriennachhilfe] = useState([
        {label: "Ja", value: 1, selected: data.feriennh === 1},
        {label: "Nein", value: 0, selected: data.feriennh === 0}
    ])

    useEffect(() => {
        setFeriennachhilfe([
            {label: "Ja", value: 1, selected: data.feriennh === 1},
            {label: "Nein", value: 0, selected: data.feriennh === 0}
        ])
    }, [data])

    const handleSelect = (selected: string | number) => {
        setData({bevorzugter_bezirk: selected})
    }

    const maxDate = moment().subtract(14, 'years').format('YYYY-MM-DD');

    const sexOptions = [
        {
            label: 'männlich',
            value: 'm'
        },
        {
            label: 'weiblich',
            value: 'w'
        },
        {
            label: 'diverse',
            value: 'div'
        }
    ]

    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={`${HelperStyles.textLeft} ${HelperStyles.flex} ${HelperStyles.itemsCenter}`}>Angaben
                        zur Person <InfoSign
                            style={{...InfoButtonYellowBig, marginLeft: '10px'}}
                            text={'Deine Daten werden nicht veröffentlicht, sondern nur bei gezielten Anfragen weitergegeben'}/>
                    </h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <CustomDropdown
                        label="Geschlecht"
                        name="anrede"
                        value={''}
                        options={sexOptions}
                        required={false}
                    />
                </div>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.vorname}
                        type="text"
                        label="Vorname"
                        name="vorname"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.nachname}
                        type="text"
                        label="Nachname"
                        name="nachname"
                        required={true}/>
                </div>
                <div className={HelperStyles.w25}>
                    <TextInput
                        infoBox={{
                            text: 'Du musst mindestens 15 Jahre alt sein und die Pflichtschule abgeschlossen haben, damit du Nachhilfe geben kannst.',
                            style: InfoButtonGreySmall
                        }}
                        onInputChange={handleChange(setData)}
                        value={data.gebdatum}
                        type="date"
                        label="Geburtsdatum"
                        name="gebdatum"
                        required={true}
                        max={maxDate}
                    />

                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.strasse}
                        type="text"
                        label="Straße, Nr."
                        name="strasse"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.plz}
                        type="number"
                        label="Postleitzahl"
                        name="plz"
                        required={true}/>
                </div>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.ort}
                        type="text"
                        label="Ort"
                        name="ort"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <CustomDropdown
                        label="Wohnbezirk"
                        value={data.bezirk_id}
                        name="bezirk_id"
                        options={options}
                        required={true}
                        freeTextId={'bildungsabschluss_freetext'}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.telefon}
                        type="tel"
                        label="Telefon"
                        name="telefon"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.email}
                        type="text"
                        label="E-Mail"
                        name="email"
                        required={true}/>
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.beruf}
                        type="text"
                        label="Beruf"
                        name="beruf"
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiSelectDropdown name={'bevorzugter_bezirk'} options={bezirkMultiOptions}
                                         selectedValues={data.bevorzugter_bezirk}
                                         onSelect={handleSelect} label={'Gebe Nachhilfe im Bezirk'}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <CustomDropdown
                        label="Höchster Bildungsabschluss"
                        value={data.bildungsabschluss}
                        name="bildungsabschluss"
                        freeTextId="bildungsabschluss_freetext"
                        options={highestEducation}
                        textValue={data.bildungsabschluss_freetext}
                        onChange={handleChange(setData)}
                        required={true}/>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <RadioButtons
                        options={ferienNachhilfe}
                        name="feriennh"
                        title="Geben Sie auch in den Ferien Nachhilfe?"
                        onChange={handleChange(setData)}
                        valueParser={parseInt}
                    />
                </div>
            </div>
        </>
    );
};

export default FirstStep;
