import EnterJob from "../constants/EnterJob.constants";
import {Steps} from '../../pages/EnterJobFormSteps/Steps'

type actionObject = {
  type: string;
  payload: any;
};

const initialState = {
  step: 1,
  lastActivatedStep: 1,
  bsOut: false,
  direction: "NEXT",
  data: {
    firma: "",
    strasse: "",
    plz: "",
    ort: "",
    bezirk_id: 0,
    telefon: 0,
    telefax: 0,
    email: "",
    homepage: "",
    anrede: "",
    fvorname: "",
    fnachname: "",
    bs_firma: null,
    bs_strasse: null,
    bs_plz: null,
    bs_ort: null,
    bs_bezirk_id: null,
    bs_telefon: null,
    bs_telefax: null,
    bs_email: null,
    bs_homepage: null,
    bs_anrede: null,
    bs_vorname: null,
    bs_nachname: null,
    buero: 0,
    tourismus: 0,
    technik: 0,
    handwerk: 0,
    sozial: 0,
    verkauf: 0,
    reinigung: 0,
    sonstiges: 0,
    sonsttext: "",
    taetigkeit: "",
    vorkenntnisse: "",
    bevausbildung: "",
    dauer: "",
    m1: 0,
    m2: 0,
    m3: 0,
    m4: 0,
    m5: 0,
    m6: 0,
    m7: 0,
    m8: 0,
    m9: 0,
    m10: 0,
    m11: 0,
    m12: 0,
    gehalt: "",
    mindestalter: 0,
    gesamtanz: 0,
    ferialjob: 0,
    pflichtpraktikum: 0,
    nebenjob: 0,
    samstagsaushilfe: 0,
    wohnmoeglichkeit: 0,
    b_schriftlich: 0,
    b_telefonisch: 0,
    b_email: 0,
    b_online: 0,
    b_online_link: "",
    regionaljobs: 0,
    datenschutz: 0
  }
};

const EnterJobForm = (state = initialState, action: actionObject) => {
  switch (action.type) {
    case EnterJob.SUBMIT:
      break;

    case EnterJob.NEXT:
      if (state.lastActivatedStep === state.step) {
        return {
          ...state,
          step: state.step + 1,
          lastActivatedStep: state.step + 1,
          direction: "NEXT"
        };
      }
      return { ...state, step: state.step + 1, direction: "NEXT" };

    case EnterJob.PREV:
      return { ...state, step: state.step - 1, direction: "PREV" };

    case EnterJob.SET_DATA:
      const payload = { ...action.payload };
      return { ...state, data: { ...state.data, ...payload } };

    case EnterJob.SET_STEP:
      if (action.payload <= state.lastActivatedStep && state.lastActivatedStep !== Object.keys(Steps).length) {
        return { ...state, step: action.payload };
      }

    case EnterJob.BS_OUT:
      return { ...state, bsOut: !state.bsOut };
    default:
      return state;
  }
};

export default EnterJobForm;
